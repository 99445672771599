@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@300;400;500;600&display=swap");

:root {
  --fontColor: #ec1e23;
  --buttonColor: #1c196a;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}



/*  Navbar Css config === */
.navbar {
  line-height: 2vh;
  height: 15vh;
  box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 10% 0 15%; */
  padding: 0 5% 0 15%; /* use this for container management */
}

.features-home {
  background: rgb(28, 25, 106);
  background: linear-gradient(
    90deg,
    rgba(28, 25, 106, 1) 43%,
    rgba(182, 29, 54, 1) 100%,
    rgba(237, 30, 36, 1) 100%
  );
}

.step-text {
  font-size: 35px;
  font-weight: 600;
}

.stats-container {
  background-color: #b9bcc1;
}
.tailfooter {
  background-color: #000000;
  color: #fff;
}

li {
  text-decoration: none;
  list-style: none;
  color: #000000;
}

a {
  text-decoration: none;
}

/* .logo {
  font-size: 30px;
  color: #000000;
} */

.logoimg {
  height: 140px;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  width: 75%;
}


.nav-links li {
  color: var(--fontColor);
  font-size: 20px;
  padding: 0 10px;
  margin: 0 10px;
  cursor: pointer;
  text-transform: capitalize;
}

.nav-links li:hover {
  color: var(--buttonColor);
}

.hero {
  text-align: center;
  margin-top: 15%;
}

.hero h1 {
  text-align: center;
  font-size: 80px;
}

.button-mail{
  color: rgb(238, 238, 238);
  padding: 9px;
  border-radius: 15px;
  height: 40px;
  width: 190px;
  background-color: #1d196c;
}

.button-mail:hover{
  cursor: pointer;
  background-color: #0c0a29;
}

.mobile-menu-icon {
  display: none;
}

/* Home section */
/* .hero--two--container {
  padding: 0 10% 0 15%;
} */

/* AboutUs Section === */

.aboutus-hero {
  /* background: url(./Components/images/aboutus-hero.jpg); */
  height: 65vh;
  width: auto;
  background-size: 100%;
  background-repeat: no-repeat;
  color: rgb(246, 245, 245);
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  display: flex;
  font-size: 30px;
  justify-content: center;
  align-items: center;
}

.mission-panel{
  height: 60vh;
  width: 100%;
  font-size: 1.2rem;
  line-height: 24px;
}

.abs-container {
  max-width: 1160px;
  margin: 100px auto;
  padding: 10px;
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 10px;
  /* justify-content: center; */
}

.abs-item {
  font-weight: 300;
  font-size: 15px;
  padding: 20px;
}

.abs-item:nth-of-type(1) {
  background: url(./Components/images/radio-internet.jpg);
  height: 46vh;
  background-size: 100%;
  background-repeat: no-repeat;
}
.provider-text {
  margin-top: -14px;
}
.abs-overall {
  width: auto;
  background: #efefef;
}
.abs-container--section {
  max-width: 1160px;
  height: 50vh;
  margin: 100px auto;
  padding: 10px;
  display: grid;
  grid-template-columns: 3fr 3fr;
  gap: 10px;
  text-align: center;
}

.abs-item--section {
  font-weight: 300;
  font-size: 15px;
  padding: 90px 40px 0 40px;
}

.services-banner-bg{
  background-image: url(./Components/images/banner.png);
}

.banner-img{
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
}

.abs-item--section:nth-of-type(1) {
  width: auto;
}



/* =========Contact Us========= */

.contact-hero {
  height: 60vh;
  background: url("https://www.ukg.com/sites/default/files/styles/ukg_max_width_1600px/public/legacy/ukg/media/images/CV1819_desktop.png.webp?itok=_72F-ByO");
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  background-size: 100% 100%;
}

.contact-hero-typography {
  margin: 0 10% 0 15%;
  padding-top: 7%;
  font-size: 20px;
  color: #f0f1f8;
}

.contact-hero-typography{
  font-weight: 600;
  font-size: 35px;
}

.contact-container {
  width: 85%;
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.contact-container .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-container .content .left-side {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}

.promo-price{
  color: #ec1e25;
  font-weight: 600;
  font-size: 30px;
}

.contact-content .left-side::before {
  content: "";
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}

.content .left-side .details {
  margin: 14px;
  text-align: center;
}

.content .left-side .details i {
  font-size: 30px;
  color: #3e2093;
  margin-bottom: 10px;
}

.content .left-side .details .topic {
  font-size: 18px;
  font-weight: 500;
}

.content .left-side .details .text-one,
.content .left-side .details .text-two {
  font-size: 14px;
  color: #afafb6;
}

.contact-container .content .right-side {
  width: 75%;
  margin-left: 75px;
}

.content .right-side .topic-text {
  font-size: 23px;
  font-weight: 600;
  color: #3e2093;
}

.right-side .input-box {
  height: 50px;
  width: 100%;
  margin: 12px 0;
}

.right-side .input-box input,
.right-side .input-box textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #f0f1f8;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}

.right-side .message-box {
  min-height: 110px;
}

.right-side .input-box textarea {
  padding-top: 6px;
}
.right-side .button {
  display: inline-block;
  margin-top: 12px;
}
.right-side .button input[type="button"] {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #3e2093;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button input[type="button"]:hover {
  background: #5029bc;
}

.email-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* Extra Css applied on framework css */

.card-element {
  transition: transform 0.4s;
}

.card-element:hover {
  border: solid #ec1e23;
  transform: scale(1.1);
}

.hero-txt-one {
  font-size: 3.1rem;
}

.hero-txt-three {
  margin-top: -40px;
}

.footer-links:hover {
  color: #ec1e23;
}

.PricingHero-text {
  font-size: 3.8rem;
  font-weight: 600;
  position: absolute;
  color: rgb(245, 240, 240);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* button {
  font-size: 19px;
  border-radius: 15px;
  padding: 15px 50px;
  cursor: pointer;
} */

.feature-card {
  transition: transform 0.4s;
}
.feature-card:hover {
  transform: scale(1.2);
}

button:hover {
}

.ph-text-one {
  font-size: 1.5rem;
}

.PricingHero-text-Two {
  color: white;
  width: 40%;
  height: 27vh;
  float: right;
  margin-top: 10%;
}

/* ====================================================== */

/* Media Queries === */

@media (max-width: 820px) {
  /* navbar === */

  .navbar {
    height: 15vh;
  }
  .nav-links {
    display: none;
  }
  .logoimg {
    height: 100px;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;
    left: 0;
    z-index: 3;
    top: 10%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: white;
  }
  ul li {
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-align: center;
  }

  .home--container {
    padding: 0;
  }
  .mobile-menu-icon {
    position: absolute;
    display: block;
    right: 20px;
    z-index: 4;
  }

  .hero h1 {
    font-size: 50px;
  }

  /* aboutus section === */
  .aboutus-hero {
    background: url(./Components/images/aboutus-hero.jpg);
    height: 28vh;
    width: auto;
    background-size: 100%;
    background-repeat: no-repeat;
    color: rgb(246, 245, 245);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }

  .abs-container {
    grid-template-columns: 1fr;
  }

  .abs-item:nth-of-type(1) {
    height: 40vh;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .abs-container--section {
    grid-template-columns: 1fr;
    padding: 15px;
    width: auto;
    height: 90vh;
  }

  .abs-item--section {
    text-align: left;
    padding: 15px;
  }

  /* =========contact us ======== */

  .contact-hero {
    height: 33vh;
    width: auto;
  }

  .contact-hero-typography {
    padding-top: 18%;
    font-size: 19px;
  }

  .contact-container {
    margin: 40px 0;
    height: 100%;
  }
  .contact-container .content {
    flex-direction: column-reverse;
  }
  .contact-container .content .left-side {
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .contact-container .content .left-side::before {
    display: none;
  }
  .contact-container .content .right-side {
    width: 100%;
    margin-left: 0;
  }

  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    text-align: center;
  }

  .PricingHero-text {
    font-size: 27px;
    font-weight: 600;
  }

  .parallax-pricing {
    background: url(./Components/images/pricingHero.jpg);
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
    height: 34vh;
    width: auto;
    background-size: 100%;
    background-repeat: no-repeat;
    color: rgb(246, 245, 245);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }
  .parallax-pricing-two {
    background: url(./Components/images/creditcard.jpg);
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
    height: 28vh;
    width: auto;
    background-size: 100%;
    background-repeat: no-repeat;
    color: rgb(246, 245, 245);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }

  .PricingHero-text-Two {
    color: white;
    width: 90%;
    height: 25vh;
    margin-top: 5%;
  }

  .ph-text-one {
    font-size: 1.5rem;
  }
}

/* =======Devices with 960px and above Media Queries */

@media (min-width: 960px) {
  .parallax {
    /* The image used */
    /*  background-image: url("img_parallax.jpg"); */
    background: url(./Components/images/aboutus-hero.jpg);
    background-size: 100%;
    background-repeat: no-repeat;

    /* Set a specific height */
    min-height: 65vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .parallax-pricing {
    background: url(./Components/images/pricingHero.jpg);
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
    background-size: 100%;
    background-repeat: no-repeat;

    /* Set a specific height */
    min-height: 65vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .carouselcap h3 {
    font-size: 3rem;
  }

  .carouselcap p {
    font-size: 2.5rem;
  }

  .carouselcap {
    font-weight: 600;
    margin-top: -50%;
    margin-left: -5%;
    text-align: left;
  }

  .ph-text-one {
    font-size: 1.9rem;
  }

  .parallax-pricing-two {
    background: url(./Components/images/creditcard.jpg);
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
    background-size: 100%;
    background-repeat: no-repeat;

    /* Set a specific height */
    min-height: 65vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* @media (max-width: 950px) {
  .contact-container{
    width: 90%;
    padding: 30px 40px 40px 35px ;
  }
  .contact-container .content .right-side{
   width: 75%;
   margin-left: 55px;
}
} */


@media (max-width: 1280px){
  .logoimg{
    height: 90px;
  }
}

